import React, { useState, memo, useEffect, useCallback } from 'react';
import CompanyFeedItem from '../company/CompanyFeedItem';
import { Button, Flex } from '../primitives';
import FeedFilter from '../FeedFilter';

const DISPLAY_INCREMENTS = 25;

const UserActivityFeed = ({ feed, refreshFeed, watchlistCompanies }) => {
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);
  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const [activeFilters, setActiveFilters] = useState(['All']);
  const [activeVerticals, setActiveVerticals] = useState(['All']);
  const [activityFeed, setActivityFeed] = useState(feed);

  useEffect(() => {
    const lowerCaseFilters = activeFilters.map((a) => a.toLowerCase());
    const lowerCaseVerticals = activeVerticals.map((a) => a.toLowerCase());

    const sanitizedFeed = feed
      .filter((f) => {
        if (activeFilters.includes('All')) return true;
        return lowerCaseFilters.includes(f.type.toLowerCase()); // Adjusted to handle array
      })
      .filter((f) => {
        if (activeVerticals.includes('All')) return true;
        if (!f.tv_vertical) return false;
        return lowerCaseVerticals.includes(f.tv_vertical.toLowerCase()); // Adjusted to handle array
      })
      .slice(0, numDisplay);

    setActivityFeed(sanitizedFeed);
  }, [activeVerticals, activeFilters, feed, numDisplay, setActivityFeed]);

  const handleOnVerticalChange = useCallback(
    (vertical) => {
      if (vertical === 'All') {
        setActiveVerticals(['All']);
      } else {
        let newVerticals = activeVerticals;

        if (activeVerticals.includes(vertical)) {
          newVerticals = activeVerticals.filter((item) => item !== vertical);
        } else {
          const verticalsSet = new Set([...activeVerticals, vertical].filter((a) => a !== 'All'));
          newVerticals = Array.from(verticalsSet);
        }

        setActiveVerticals(newVerticals.length === 0 ? ['All'] : newVerticals);
      }
    },
    [activeVerticals],
  );

  const handleFeedTypeChange = useCallback(
    (filter) => {
      if (filter === 'All') {
        setActiveFilters(['All']);
      } else {
        let newFilters = activeFilters;

        if (activeFilters.includes(filter)) {
          newFilters = activeFilters.filter((item) => item !== filter);
        } else {
          const filtersSet = new Set([...activeFilters, filter].filter((a) => a !== 'All'));
          newFilters = Array.from(filtersSet);
        }

        setActiveFilters(newFilters.length === 0 ? ['All'] : newFilters);
      }
    },
    [activeFilters],
  );

  return (
    <div className="user-feed">
      <FeedFilter
        withFeedTypeFilter
        handleFeedTypeChange={handleFeedTypeChange}
        withVerticalFilter
        handleOnVerticalChange={handleOnVerticalChange}
        isDashboard
        padding="20px"
        margin="0"
        borderRadius={false}
        boxShadow={false}
      />

      <div className="list-table">
        {activityFeed.map((f, index) => (
          <CompanyFeedItem
            key={f.tv_feed_id ?? index}
            item={f}
            isCompany={false}
            refreshFeed={refreshFeed}
            withCompanyName
            watchlistCompanies={watchlistCompanies}
            isDashboard
          />
        ))}
      </div>

      {numDisplay < feed?.length && (
        <Flex fill justify="center" marginTop="16">
          <Button onClick={increment} rounded>
            See More
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default memo(UserActivityFeed);
