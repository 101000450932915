import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';
import { isEqual } from 'lodash';
import Boundary from '../Boundary';
import { AdvancedSearchV2Context } from './AdvancedSearchV2Context';
import AdvancedSearchV2FiltersBar from './AdvancedSearchV2FiltersBar';
import AdvancedSearchV2FilterModal from './AdvancedSearchV2FilterModal';
import AdvancedSearchV2DealStats from './AdvancedSearchV2DealStats';
import { getDealFeedCompanies } from '../../BackendAPI';
import ListCompanyView from '../ListCompanyView';
import { Card, Icon } from '../primitives';
import captureError from '../../utils/sentry';
import { VERTICAL_FILTERS } from '../../pages/Engaged';

export const FILTERED_ROUND_TYPES = [
  'equity_crowdfunding',
  'debt_financing',
  'post_ipo_secondary',
  'non_equity_assistance',
  'corporate_round',
  'grant',
  'private_equity',
  'angel',
  'secondary_market',
  'post_ipo_equity',
  'post_ipo_debt',
  'series_unknown',
  'undisclosed',
  'convertible_note',
];
const COMPANY_COLUMNS = [
  { text: 'Name', colClass: 'name', key: 'company_name', sortable: true },
  { colClass: 'description', text: 'Description', key: 'short_description', sortable: false },
  { text: 'Last Round', colClass: 'stage', key: 'last_round', sortable: true },
  { text: 'Amount', colClass: 'amount', key: 'raised_amount_usd', sortable: true },
  { text: 'Round Date', colClass: 'round_date', key: 'round_date', sortable: true },
  { text: 'VC Score', colClass: 'score', key: 'tv_company_investor_score', sortable: true },
  { colClass: 'subvertical', text: 'Sub-Vertical', key: 'subvertical', sortable: false },
  { text: 'Key Investors', colClass: 'description', key: 'key_investors', sortable: false },
  { text: 'Status', colClass: 'status', key: 'major_status', sortable: false },
];

const StyledCard = styled(Card).attrs({
  width: '100%',
  height: '100%',
  justify: 'center',
  align: 'center',
  direction: 'column',
})`
  min-height: 600px;
  margin-top: 20px;
`;

const StyledRow = styled.div`
  margin-bottom: 100px;
`;

const Container = styled.div`
  @media (max-width: 991px) {
    margin-top: 70px;
    .search-filters-container {
      display: none;
    }

    .results-container.col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .toggle-view-btns .btn {
      display: flex;
      font-size: 14px !important;
      width: 100%;
      align-items: center;
      justify-content: center;
      color: #6e6e6e;

      &:hover {
        background-color: #ddd;
      }

      .icon-wrapper {
        margin-left: 8px;
      }
    }
  }

  @media (min-width: 991px) {
    .toggle-view-btns {
      display: none;
    }
  }

  .results-container {
    width: 100%;

    .table-container {
      width: 100%;
      position: relative;

      .column {
        &.name {
          width: 8%;
          min-width: 100px;
        }
        &.description {
          width: 15%;
          min-width: 180px;
        }
        &.amount {
          width: 5%;
          min-width: 80px;
        }
        &.score {
          width: 5%;
          min-width: 80px;
        }
        &.round_date {
          width: 8%;
          min-width: 100px;
        }
        &.status {
          min-width: 130px;
        }
      }
    }
  }
`;

function AdvancedSearchContentFallback() {
  return (
    <div className="container col-md-9">
      <StyledCard direction="column">
        <p>There was an issue. Please reset the filter and try again.</p>
        <p>If it continues reach out to the IT team.</p>
      </StyledCard>
    </div>
  );
}

export const formatQueryParams = (state) => {
  const queryParams = {
    verticals: [],
    vc_score: '1',
    stage: [],
    vc_expertise: [],
    round_type: false,
    verticals_full_name: [],
  };

  for (const [key, array] of Object.entries(state)) {
    array.forEach((a) => {
      if (a.checked) {
        if (key === 'vc_score' || key === 'round_type') {
          queryParams[key] = a.value;
        } else if (key === 'verticals') {
          queryParams[key].push(a.value);
          const vertical = VERTICAL_FILTERS.find((v) => v.name === a.value);

          if (vertical?.longName) {
            queryParams.verticals_full_name.push(vertical?.longName);
          }
        } else {
          queryParams[key].push(a.value);
        }
      }
    });
  }

  return queryParams;
};

function AdvancedSearchContent() {
  const [companies, setCompanies] = useState([]);
  const [state] = useContext(AdvancedSearchV2Context);
  const [showModal, toggleModal] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [hasError, setError] = useState(false);
  const [prevSearchFilters, setPrevSearchFilters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDealFeedCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      const queryParams = formatQueryParams(state);
      const results = await getDealFeedCompanies(queryParams);

      const fundedCompanies = results
        .filter((company) => {
          if (queryParams.round_type) return true;
          return company.last_round && !FILTERED_ROUND_TYPES.includes(company.last_round);
        })
        .map((company) => {
          if (company.round_date) {
            if (differenceInDays(new Date(), new Date(company.round_date)) <= 7) {
              return { ...company, is_recent: true };
            }
          }
          return { ...company, is_recent: false };
        });
      if (fundedCompanies.length > 0) {
        setCompanies(fundedCompanies);
      } else {
        setNoResults(true);
      }
    } catch (error) {
      setError(error);
      captureError(error);
    } finally {
      setIsLoading(false);
    }
  }, [state]);

  useEffect(() => {
    if (!isEqual(state, prevSearchFilters)) {
      fetchDealFeedCompanies();
      setPrevSearchFilters(JSON.parse(JSON.stringify(state)));
    }
  }, [state, companies, prevSearchFilters, fetchDealFeedCompanies]);

  return (
    <Container className="container page-container advanced-search company-profile">
      <div className="toggle-view-btns btn-group flagged-btns tv-card">
        <button type="button" onClick={() => toggleModal(true)} className="btn btn-default">
          Filter Menu
          <Icon name="filter" />
        </button>
      </div>

      <StyledRow className="row">
        <div className="col-md-3 search-filters-container">
          <div className="search-filters tv-card" direction="column">
            <AdvancedSearchV2FiltersBar />
          </div>
        </div>

        <div className="results-container col-md-9">
          <AdvancedSearchV2DealStats companies={companies} isLoading={isLoading} />
          <Boundary fallback={<AdvancedSearchContentFallback />}>
            <ListCompanyView
              companies={isLoading ? [] : companies}
              tableMaxHeight="220px"
              tableMaxHeightMobile="318px"
              columns={COMPANY_COLUMNS}
              defaultSortKey="round_date"
              noResults={noResults}
              hasError={hasError}
              withMargin={false}
              innerTableWidth="120%"
            />
          </Boundary>
        </div>
      </StyledRow>
      <AdvancedSearchV2FilterModal showModal={showModal} toggleModal={toggleModal} />
    </Container>
  );
}

export default function AdvancedSearchContentV2(props) {
  return (
    <Boundary fallback={<AdvancedSearchContentFallback />}>
      <AdvancedSearchContent {...props} />
    </Boundary>
  );
}
