import React, { useState, useEffect, useCallback, memo, useContext } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import captureError from '../utils/sentry';
import Boundary from '../components/Boundary';
import { Card, Flex, Button, Loader } from '../components/primitives';
import { getDashboardFeed } from '../BackendAPI';
import { getUserFirstName, getUserEmail } from '../utils/Auth';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import UserActivityFeed from '../components/dashboard/UserActivityFeed';
import DealFeedSidebar from '../components/dashboard/DealFeedSidebar';
import {
  AdvancedSearchV2Context,
  AdvancedSearchV2Provider,
} from '../components/advancedSearch/AdvancedSearchV2Context';
import { buildRouteQueryParams } from '../components/advancedSearch/AdvancedSearchV2Header';
import { isRecent } from '../util';
import PageHeader from '../components/PageHeader';
import { getUserId } from '../utils/Auth2';
import FeedFilter from '../components/FeedFilter';
import { parseParams } from './AdvancedSearch';
import useIsMobile from '../hooks/useIsMobile';

import '../styles/components/Dashboard.css';

const AMPLITUDE_API_KEY = '9394220f61d6405247e15adb9f4acbeb';

const GREETINGS = [
  'Howdy',
  'Hello',
  'Hola',
  'Bonjour',
  'Guten Tag',
  'Hallo',
  'Hei',
  'Aloha',
  'Nǐ hǎo',
  "Kon'nichiwa",
];

const LeftColumn = styled.div`
  padding-left: 50px;
  padding-right: 10px;
  @media (max-width: 991px) {
    padding-left: 10px;
  }
`;

const MiddleColumn = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 265px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Container = styled.div`
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

const getGreeting = () => {
  const idx = Math.floor(GREETINGS.length * Math.random());
  return `${GREETINGS[idx]}, ${getUserFirstName()} 👋`;
};

function DashboardErrorFallback({ refreshFeed }) {
  return (
    <Card className="dashboard-error" align="center" justify="center">
      <Flex direction="column" align="center" justify="center" fill>
        <h5>There was an issue loading your dashboard feed</h5>
        <p>Retry and if the issue perists contact the IT team.</p>
        {refreshFeed && <Button onClick={refreshFeed}>Retry</Button>}
      </Flex>
    </Card>
  );
}

function DashboardLoadingFallback() {
  return (
    <Flex align="center" justify="center" direction="column" fill className="page-loader user-feed">
      <FeedFilter
        withFeedTypeFilter
        handleFeedTypeChange={() => {}}
        withVerticalFilter
        handleOnVerticalChange={() => {}}
        isDashboard
        padding="20px"
        margin="0"
        borderRadius={false}
        boxShadow={false}
      />
      <Flex
        align="center"
        justify="center"
        direction="column"
        fill
        className="page-loader list-table card"
      >
        <Loader />
      </Flex>
    </Flex>
  );
}

function DashboardContent() {
  const [error, setError] = useState(false);
  const [feed, setFeed] = useState([]);
  const [state] = useContext(AdvancedSearchV2Context);
  const params = buildRouteQueryParams(state);
  window.history.replaceState({}, '', `?${params}`);
  // const [watchlistCompanies, setWatchlistCompanies] = useState([]);

  // const getWatchlistCompanies = useCallback(async () => {
  //   try {
  //     const userId = await getUserId();
  //     const companies = await getUserWatchlistCompanies(userId);
  //     setWatchlistCompanies(companies);
  //   } catch (err) {
  //     captureError(`Dashboard watchlist companies failed: ${err}`);
  //   }
  // }, []);

  useEffect(() => {
    const email = getUserEmail();
    amplitude.init(AMPLITUDE_API_KEY);
    amplitude.setUserId(email);
  }, []);

  const getFeed = useCallback(async () => {
    try {
      const userId = await getUserId();
      const dashboardFeed = await getDashboardFeed(userId);
      const filteredFeed = dashboardFeed
        .map((f) => {
          f.isRecent = isRecent(f.feed_time);
          return f;
        })
        .filter((f) => f.type !== 'list')
        .sort((a, b) => new Date(b.feed_time).getTime() - new Date(a.feed_time).getTime());

      setFeed(filteredFeed);
    } catch (err) {
      captureError(`Dashboard feed failed: ${err}`);
      setError(true);
    }
  }, []);

  useEffect(() => {
    // getWatchlistCompanies();
    getFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshFeed = useCallback(() => {
    setError(false);
    getFeed();
  }, [getFeed]);

  if (error) {
    return <DashboardErrorFallback refreshFeed={refreshFeed} />;
  }

  if (feed?.length === 0) {
    return <DashboardLoadingFallback />;
  }

  return <UserActivityFeed feed={feed} refreshFeed={getFeed} watchlistCompanies={[]} />;
}

const Dashboard = () => {
  const [mobileNav, setMobileNav] = useState('feed');
  const location = useLocation();
  const params = parseParams(location);
  const isMobile = useIsMobile();

  return (
    <AdvancedSearchV2Provider params={params}>
      <div>
        <Helmet>
          <title>Dashboard - TV CRM</title>
        </Helmet>

        <PageHeader title={getGreeting()} />

        <div className="mobile-selector">
          <ul>
            <li className={mobileNav === 'feed' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('feed')}>
                Feed
              </button>
            </li>
            <li className={mobileNav === 'sidebar' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('sidebar')}>
                Sidebar
              </button>
            </li>
            <li className={mobileNav === 'deal-feed' ? 'active' : ''}>
              <button type="button" onClick={() => setMobileNav('deal-feed')}>
                Deal Feed
              </button>
            </li>
          </ul>
        </div>
        <Container className="row container dashboard-container" isMobile={isMobile}>
          <LeftColumn className={`feed${mobileNav === 'feed' ? '' : ' hidden-mobile'} col-md-6`}>
            <div className="company-panel-feed">
              <h6 className="section-header widget">Activity Feed</h6>
              <Boundary
                loadingFallback={<DashboardLoadingFallback />}
                fallback={<DashboardErrorFallback />}
              >
                <DashboardContent />
              </Boundary>
            </div>
          </LeftColumn>
          <MiddleColumn
            className={`sidebar${mobileNav === 'sidebar' ? '' : ' hidden-mobile'} col-md-3`}
          >
            <DashboardSidebar />
          </MiddleColumn>
          <RightColumn
            className={`sidebar${mobileNav === 'deal-feed' ? '' : ' hidden-mobile'} col-md-3`}
          >
            <DealFeedSidebar />
          </RightColumn>
        </Container>
      </div>
    </AdvancedSearchV2Provider>
  );
};

export default memo(Dashboard);
