import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex';

const Container = styled(Flex).attrs({
  align: 'center',
  direction: 'column',
})`
  ${({ width = '100%' }) => `width: ${width};`}
  height: inherit;
`;

const TableBody = ({ children, width }) => {
  return (
    <Container className="table-body" width={width}>
      {children}
    </Container>
  );
};

export default TableBody;
